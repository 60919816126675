<template>
  <div class="createcollection-style">
    <div class="bigbox">
      <div class="box1">{{$t('text.t82')}}</div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t84')}}</div>
      <div class="bigbox-t2 bigbox-mb10">{{$t('text.t85')}} 160*160 {{$t('text.t87')}}</div>
      <div class="bb-mb20">
        <van-uploader
          :max-count="1"
          :after-read="afterRead1"
          v-model="fileList1"
          multiple
          @delete="deleteImg1"
        />
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t86')}}</div>
      <div class="bigbox-t2 bigbox-mb10">{{$t('text.t85')}} 270*270 {{$t('text.t87')}}</div>
      <div class="bb-mb20">
        <van-uploader
          :max-count="1"
          :after-read="afterRead2"
          v-model="fileList2"
          multiple
          @delete="deleteImg2"
        />
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t88')}}</div>
      <div class="bigbox-t2 bigbox-mb10">{{$t('text.t85')}} 750*410 {{$t('text.t87')}}</div>
      <div class="bb-mb20">
        <van-uploader
          :max-count="1"
          :after-read="afterRead3"
          v-model="fileList3"
          multiple
          @delete="deleteImg3"
        />
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t89')}}</div>
      <div class="bb-inp bb-mb20">
        <el-input v-model="names" :placeholder="$t('text.t90')"></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t91')}}</div>
      <div class="bb-inp bb-mb20">
        <el-input
          type="textarea"
          v-model="describe"
          :placeholder="$t('text.t92')"
        ></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t425')}}</div>
      <div class="bb-inp bb-mb20">
        <el-select v-model="features" multiple :placeholder="$t('text.t426')">
          <el-option
            v-for="item in featuresList"
            :key="item.id"
            :label="language==1?item.title:item.etitle"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t281')}}</div>
      <div class="bb-inp bb-mb20">
        <el-input v-model="link" :placeholder="$t('text.t285')"></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t288')}}</div>
      <div class="bb-inp bb-mb20">
        <el-input v-model="twitter" :placeholder="$t('text.t290')"></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">Discard</div>
      <div class="bb-inp bb-mb20">
        <el-input v-model="discard" :placeholder="$t('text.t291')"></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t289')}}</div>
      <div class="bb-inp bb-mb20">
        <el-input v-model="telegram" :placeholder="$t('text.t292')"></el-input>
      </div>
      <div class="bb-btn bb-mb20">
        <van-button :loading-text="$t('text.t67')" :loading="isloading" block color="#323232" @click="editInfo">{{$t('text.t68')}}</van-button>
      </div>
      <!-- <div class="bb-btn">
        <van-button @click="deleteInfo" :loading="isloading" block color="#323232" plain>{{$t('text.t94')}}</van-button>
      </div> -->
    </div>
  </div>
</template>
<script>
import {mapState,mapActions} from 'vuex'
export default {
  components: {},
  data() {
    return {
      names: "",
      describe: "",
      type:'',
      fileList1: [],
      fileList2: [],
      fileList3: [],
      upimg1: "",
      upimg2: "",
      upimg3: "",
      isloading:false,
      ids:'',
      updateType:'',
      link:'',
      twitter:'',
      discard:'',
      telegram:'',
      features:[],
      featuresList:[],
      chainType:''
    };
  },
  computed: {
    ...mapState(["language"]),
  },
  created(){
    this.ids = this.$route.query.id
    this.updateType = this.$route.query.type
    this.chainType = this.$route.query.chainType;
    console.log(this.ids);
    if(this.ids){
      this.getInfo()
    }
    this.getflList()
  },
  mounted() {
    
  },
  methods: {
    ...mapActions(["getMyCollection"]),
    getflList(){
      this.$http.post('/casting/classificationList',{
        page:1,
        limit:100,
        language:this.language
      }).then(res=>{
        this.featuresList = res.data.data
      })
    },
    deleteInfo(){
      this.$http.post('/collection/delCollection',{
        id:this.ids,
        language:this.language
      }).then(res=>{
        this.$bus.$emit('update')
        this.$toast(res.msg)
        this.isloading = false
        this.$router.go(-1)
      }).catch(()=>{
        this.isloading = false
      })
    },
    getInfo(){
      this.$http.get('/collection/collectionDetail',{
        id:this.ids,
        language:this.language,
        chainType:this.chainType
      }).then(res=>{
        const {collectionimage,selectedimage,backgroundimage,collectionname,collectionintro,link,telegram,discard,twitter,classification_ids} = res.data
        this.upimg1 = collectionimage
        this.upimg2 = selectedimage
        this.upimg3 = backgroundimage
        this.names = collectionname
        this.describe = collectionintro
        this.link = link
        this.telegram = telegram
        this.discard = discard
        this.twitter = twitter
        console.log(classification_ids);
        this.features = classification_ids?.split(',').map(ele=>Number(ele))
        console.log(this.features);
      })
    },
    editInfo(){
      if(!this.upimg1){
        this.$toast(this.$t('text.t95'))
        return
      }
      if(!this.upimg2){
        this.$toast(this.$t('text.t96'))
        return
      }
      if(!this.upimg3){
        this.$toast(this.$t('text.t97'))
        return
      }
      if(!this.names){
        this.$toast(this.$t('text.t98'))
        return
      }
      if(!this.describe){
        this.$toast(this.$t('text.t99'))
        return
      }
      if(!this.features.length){
        this.$toast(this.$t('text.t426'))
        return
      }
      this.isloading = true
      let arg = {
        id:this.ids,
        collectionimage:this.upimg1,
        selectedimage:this.upimg2,
        backgroundimage:this.upimg3,
        collectionname:this.names,
        collectionintro:this.describe,
        language:this.language,
        link:this.link,
        telegram:this.telegram,
        discard:this.discard,
        twitter:this.twitter,
        classification_ids:this.features.join()
      }
      this.$http.post('/collection/editCollection',arg).then(res=>{
        this.$bus.$emit('update')
        this.$toast(res.msg)
        this.isloading = false
        this.$router.go(-1)
      }).catch(()=>{
        this.isloading = false
      })
    },
    afterRead1(file) {
      this.type = 1;
      this.afterReadImg(file);
    },
    afterRead2(file) {
      this.type = 2;
      this.afterReadImg(file);
    },
    afterRead3(file) {
      this.type = 3;
      this.afterReadImg(file);
    },
    afterReadImg(file) {
      file.status = "uploading";
      file.message = this.$t('text.t74');
      let ff = new FormData();
      ff.append("file", file.file);
      this.$http
        .upload("/common/upload", ff)
        .then((res) => {
          this.type == 1
            ? (this.upimg1 = res.data.url)
            : this.type == 2
            ? (this.upimg2 = res.data.url)
            : this.type == 3
            ? (this.upimg3 = res.data.url)
            : "";
          setTimeout(() => {
            file.status = "done";
            file.message = this.$t('text.t75');
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            file.status = "failed";
            file.message = this.$t('text.t76');
          }, 1000);
        });
    },
    deleteImg1() {
      this.upimg1 = "";
    },
    deleteImg2() {
      this.upimg2 = "";
    },
    deleteImg3() {
      this.upimg3 = "";
    },
  },
};
</script>

<style lang="less">
.createcollection-style {
  .bigbox {
    width: 321px;
    padding: 41px 0;
    margin: auto;
    .box1 {
      text-align: center;
      margin-bottom: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }
    .bigbox-t1 {
      font-size: 15px;
      font-weight: bold;
      color: #323232;
    }
    .bigbox-t2 {
      font-size: 12px;
      font-weight: 400;
      color: #323232;
    }
    .bigbox-mb10 {
      margin-bottom: 10px;
    }
    .bb-mb20 {
      margin-bottom: 20px;
    }
    .bb-inp {
      width: 100%;
      .el-select {
        display: block;
      }
      .bb-inp-one {
        font-size: 12px;
        color: #323232;
        height: 100%;
      }
    }
    .bb-btn {
      width: 300px;
      margin: auto;
      padding-top: 28px;
      .van-button {
        border-radius: 5px;
      }
    }
  }
}
</style>